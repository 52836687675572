<template>
  <div class="login_page_all">
    <!-- 事件名称（登录，注册还是忘记密码） -->
    <div v-if="!system.isPhone" class="title_name font36 ">
      {{ $t("loginData." + actionStep) }}
    </div>

    <el-form
      :model="ruleForm"
      :rules="rules"
      :label-position="system.isPhone ? 'top' : ''"
      ref="ruleForm"
      class="login_ruleForm"
      :status-icon="false"
    >
      <div v-if="!system.isPhone">
        <!-- 输入手机号 -->
        <phone-input
          class="input_hang"
          v-model="ruleForm.phone"
          @areaCodeChangeEvent="areaCodeChangeEvent"
          :areaCode="ruleForm.areaCode"
        ></phone-input>

        <!-- 获取验证码 -->
        <code-input
          class="input_hang"
          v-if="actionStep !== 'login'"
          :actionStep="actionStep"
          v-model="ruleForm.code"
          :ruleForm="ruleForm"
          :canSendCode="canSendCode"
          @sendCodeButEvent="sendCodeButEvent"
        ></code-input>

        <!-- 密码输入框1 -->
        <pass-input
          class="input_hang"
          :p="$t('nameInputPassword')"
          prop="password"
          inputType="password"
          v-model="ruleForm.password"
        ></pass-input>

        <!-- 密码输入框2 -->
        <pass-input
          class="input_hang"
          v-if="actionStep !== 'login'"
          :p="$t('nameInputPasswordAgin')"
          :prop="'password2'"
          inputType="password"
          v-model="ruleForm.password2"
        ></pass-input>

        <el-form-item>
          <el-button
            class="login_but "
            type="primary"
            :disabled="!ruleForm.xieyiStatys"
            @click="submitForm('ruleForm')"
            >{{ $t("loginData.subButName." + actionStep) }}</el-button
          >
        </el-form-item>
        <el-form-item>
          <xieyi
            :xieyiStatys="ruleForm.xieyiStatys"
            @xieyiClickEvent="xieyiClickEvent"
          ></xieyi>
        </el-form-item>
      </div>

      <div v-if="system.isPhone">
        <!-- 输入手机号 -->
        <phone-input
          class="input_hang"
          v-model="ruleForm.phone"
          @areaCodeChangeEvent="areaCodeChangeEvent"
          :areaCode="ruleForm.areaCode"
          :p="$t('namePhone')"
          :label="$t('loginData.wapLogin.' + actionStep + '.phone')"
        ></phone-input>

        <!-- 获取验证码 -->
        <code-input
          class="input_hang"
          v-if="actionStep !== 'login'"
          :actionStep="actionStep"
          v-model="ruleForm.code"
          :ruleForm="ruleForm"
          :canSendCode="canSendCode"
          @sendCodeButEvent="sendCodeButEvent"
          :label="$t('loginData.wapLogin.' + actionStep + '.code')"
        ></code-input>

        <!-- 密码输入框1 -->
        <pass-input
          class="input_hang"
          :p="$t('nameInputPassword')"
          :label="$t('loginData.wapLogin.' + actionStep + '.password')"
          prop="password"
          inputType="password"
          v-model="ruleForm.password"
        ></pass-input>
        <el-form-item>
          <div
            @click="qiehuanEvent('forgetPassword')"
            v-if="actionStep === 'login'"
            class="wangjimima wapfont14"
          >
            {{ $t("loginData.forgetPassword") }}
          </div>
        </el-form-item>

        <!-- 密码输入框2 -->
        <pass-input
          class="input_hang"
          v-if="actionStep !== 'login'"
          :p="$t('nameInputPasswordAgin')"
          :label="$t('loginData.wapLogin.' + actionStep + '.password2')"
          :prop="'password2'"
          inputType="password"
          v-model="ruleForm.password2"
        ></pass-input>
        <el-form-item class="xieyihang">
          <xieyi
            :xieyiStatys="ruleForm.xieyiStatys"
            @xieyiClickEvent="xieyiClickEvent"
          ></xieyi>
        </el-form-item>
        <el-form-item>
          <el-button
            class="login_but "
            type="primary"
            :disabled="!ruleForm.xieyiStatys"
            @click="submitForm('ruleForm')"
            >{{ $t("loginData.subButName." + actionStep) }}</el-button
          >
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>
<script>
import phoneInput from "../../formComponents/phoneInput";
import codeInput from "../../formComponents/codeInput";
import passInput from "../../formComponents/passInput";

import xieyi from "./xieyi";

import { verifyPasswd, verifyPhone } from "@/utils/pattern.js";

export default {
  props: ["actionStep"],
  components: {
    phoneInput,
    codeInput,
    passInput,
    xieyi
  },
  data() {
    var phone = (rule, value, callback) => {
      if (!verifyPhone(value, `+${this.ruleForm.areaCode}`)) {
        this.canSendCode = "false";
        callback(new Error(this.$t("nameInputPhone")));
      } else {
        this.canSendCode = "true";
        callback();
      }
    };
    var codeCheCK = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("nameInputYzm")));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var password = (rule, value, callback) => {
      if (!verifyPasswd(this.ruleForm.password)) {
        callback(new Error(this.$t("nameInputPasswordNotAllow"))); //密码不符合规则
      } else {
        callback();
      }
    };
    var password2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("nameInputPasswordAgin"))); //请再次输入密码
      } else if (value !== this.ruleForm.password) {
        callback(new Error(this.$t("nameInputPasswordNotSame"))); //两次密码不一致
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        phone: "",
        password: "",
        password2: "",
        areaCode: "86",
        code: "",
        xieyiStatys: true
      },
      canSendCode: "false",
      rules: {
        phone: [{ validator: phone, trigger: "blur" }],
        code: [{ validator: codeCheCK, trigger: "blur" }],
        password: [{ validator: password, trigger: "blur" }],
        password2: [{ validator: password2, trigger: "blur" }]
      },
      options: [
        {
          value: "86",
          label: "中国 +86"
        },
        {
          value: "04",
          label: "澳洲 +04"
        }
      ],
      value: "86"
    };
  },

  methods: {
    qiehuanEvent(value) {
      this.$emit("qiehuanEvent", value);
    },
    // 国家区号变更事件
    areaCodeChangeEvent(value) {
      this.ruleForm.areaCode = value;
    },
    //发送验证码按钮点击事件
    sendCodeButEvent(value) {
      this.canSendCode = value;
    },
    xieyiClickEvent() {
      this.ruleForm.xieyiStatys = !this.ruleForm.xieyiStatys;
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.ruleForm.xieyiStatys) {
            this[`to${this.actionStep}`]();
          } else {
            this.$message({
              message: "请先同意协议，待定文案",
              type: "none",
              customClass: "zidingyiel_message"
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    tologin() {
      let data = {
        phone: this.ruleForm.phone,
        areaCode: this.ruleForm.areaCode,
        password: this.ruleForm.password,
        registerLine: 3
      };
      console.log(data);
      //密码登录
      this.$request("/api/front/user/accountLogin", {
        method: "POST",
        data
      })
        .then(res => {
          if (res.code === 1) {
            localStorage.setItem("AZG_TOKEN", res.data.access_token);
            localStorage.setItem("AZG_USER", data.phone);
            this.$message({
              message: "登录成功",
              type: "none",
              customClass: "zidingyiel_message"
            });
            // this.$router.replace({
            //   path: this.$route.params.id
            // });
            if (this.$route.params.id === "/") {
              this.$router.replace({
                path: this.$route.params.id
              });
            } else {
              this.$router.go(-1);
            }
          }
        })
        .catch(() => {});
    },

    toregistered() {
      let data = {
        phone: this.ruleForm.phone,
        areaCode: this.ruleForm.areaCode,
        password: this.ruleForm.password,
        code: this.ruleForm.code,
        kind: "WebsiteRegister",
        registerLine: 3
      };
      console.log(data);
      // 注册账号
      this.$request("/api/front/user/websiteRegister", {
        method: "POST",
        data
      })
        .then(res => {
          console.log("注册成功", res);
          if (res.code === 1) {
            this.$emit("stepChangeEven", "login");
            this.$message({
              message: this.$t("loginData.registeredOK"),
              type: "none",
              customClass: "zidingyiel_message"
            });
          }
        })
        .catch(() => {});
    },
    toforgetPassword() {
      let data = {
        phone: this.ruleForm.phone,
        areaCode: this.ruleForm.areaCode,
        newPassword: this.ruleForm.password,
        code: this.ruleForm.code,
        kind: "ChangePwd",
        type: 4,
        registerLine: 3
      };
      console.log(data);
      // 修改密码
      this.$request("/api/front/user/changePassword", {
        method: "POST",
        data
      })
        .then(res => {
          console.log("修改成功", res);
          if (res.code === 1) {
            this.$emit("stepChangeEven", "login");
            this.$message({
              message: this.$t("loginData.forgetPasswordOK"),
              type: "none",
              customClass: "zidingyiel_message"
            });
          }
        })
        .catch(() => {});
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>
<style lang="less">
.el-input__inner.el-input__inner {
  border: 1px none #dcdfe6;
  border-bottom: solid 2px rgba(0, 0, 0, 0.2);
  border-radius: 0;
}
</style>
<style lang="less" scoped>
.title_name {
  font-family: "FF1";
  margin-bottom: 80px;
}
.login_ruleForm {
  width: 436px;
  margin: auto;
}
.login_page_all {
  margin-top: 165px;
}
.phone_select.phone_select {
  font-size: 50px;
  padding-right: 0;
}
.geit_code_icon {
  cursor: pointer;
}
.login_but {
  width: 436px;
  height: 49px;
  border-radius: 4px;
}
.wangjimima {
  float: left;
  color: rgba(25, 119, 251, 1);
  margin-top: calc(100vw * 1.4 / 37.5);
}
</style>
