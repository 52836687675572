<template>
  <div class="">
    <el-form-item prop="phone" :label="label">
      <el-row :gutter="20">
        <el-col :span="7">
          <el-select
            class="phone_select"
            v-model="new_areaCode"
            placeholder="请选择"
            autocomplete="off"
            @change="areaCodeChangeEvent"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="17">
          <el-input
            :placeholder="p"
            @input="change"
            type="text"
            v-model="phone"
          >
          </el-input>
        </el-col>
      </el-row>
    </el-form-item>
  </div>
</template>

<script>
//公共组件导入处;

//子组件导入处;

//方法导入处;

export default {
  components: {},
  props: ["value", "areaCode", "label", "p"],
  data() {
    return {
      phone: this.value,
      options: [
        {
          value: "86",
          label: "中国 +86"
        },
        {
          value: "61",
          label: "澳洲 +61"
        }
      ],
      new_areaCode: this.areaCode
    };
  },

  computed: {},
  methods: {
    change(value) {
      console.log(value);
      this.$emit("input", value);
    },
    areaCodeChangeEvent() {
      this.$emit("areaCodeChangeEvent", this.new_areaCode);
    }
  },
  mounted() {},
  beforeDestroy() {},
  activated() {}
};
</script>
<style scoped lang="stylus"></style>
