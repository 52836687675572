<template>
  <div class="">
    <el-form-item prop="code" :label="label">
      <el-input
        type="text"
        v-model="code"
        placeholder="请输入验证码"
        autocomplete="off"
        :maxlength="6"
        @input="change"
      >
        <div
          @click="getCOdeEvent"
          class="geit_code_icon"
          :class="{ cantrue: codeButClor }"
          slot="suffix"
        >
          {{ getCOdeBut }}
        </div>
      </el-input>
    </el-form-item>
  </div>
</template>

<script>
//公共组件导入处;

//子组件导入处;

//方法导入处;
// import { verifyPhone } from "@/utils/pattern.js";
export default {
  props: [
    "value",
    "ruleForm",
    "canSendCode",
    "label",
    "lodingTime",
    "actionStep"
  ],
  components: {},
  data() {
    return {
      code: this.value,
      getCOdeBut: this.$t("getCode"),
      times: this.lodingTime ? this.lodingTime : 60,
      getCodeButStatus: true
    };
  },
  computed: {
    codeButClor: function() {
      if (this.canSendCode == "true" && this.getCodeButStatus == true) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    change(value) {
      this.$emit("input", value);
    },
    getCOdeEvent() {
      if (this.canSendCode != "true" || this.getCodeButStatus == false) {
        return;
      }
      this.getCodeButStatus = false;
      this.$emit("sendCodeButEvent", "loding");
      const data = {
        phone: this.ruleForm.phone,
        kind: this.actionStep === "registered" ? "WebsiteRegister" : "ChangePwd"
      };
      // console.log(data, this.actionStep);
      // return;
      const url = "/api/front/user/sendSms";
      this.sending = true;
      this.$request(url, {
        method: "POST",
        data
      })
        .then(res => {
          if (res.code === 1) {
            this.lodingCountdown(1);
            this.$message({
              message: "验证码已发送到您的手机",
              type: "none",
              customClass: "zidingyiel_message"
            });
          }
        })
        .catch(() => {
          this.$emit("sendCodeButEvent", "true");
        });
    },
    lodingCountdown(t) {
      this.getCOdeBut = `${this.times - t}${this.$t("daojishi")}`;
      if (t >= this.times) {
        this.getCodeButStatus = true;
        this.$emit("sendCodeButEvent", "true");
        this.getCOdeBut = `${this.$t("getCodeOnece")}`;
        return;
      }
      setTimeout(() => {
        this.lodingCountdown(t + 1);
      }, 1000);
    }
  },
  mounted() {},
  beforeDestroy() {},
  activated() {}
};
</script>
<style scoped lang="less">
.geit_code_icon {
  cursor: pointer;
}
.cantrue {
  color: rgba(0, 0, 0, 1);
}
</style>
