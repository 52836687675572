<template>
  <div class="">
    <el-form-item :prop="prop" :label="label">
      <el-input
        :placeholder="p"
        :type="new_inputType"
        v-model="valueData"
        @input="change"
      >
        <div class="eyes_icon" @click="changeInputTypeEvent" slot="suffix">
          <img
            v-if="new_inputType == 'password'"
            :src="require('@/assets/img/login/nengkan_bu.png')"
          />
          <img v-else :src="require('@/assets/img/login/nengkan.png')" />
        </div>
      </el-input>
    </el-form-item>
  </div>
</template>

<script>
//公共组件导入处;

//子组件导入处;

//方法导入处;

export default {
  props: ["value", "prop", "label", "inputType", "p"],
  components: {},
  data() {
    return {
      valueData: this.value,
      new_inputType: this.inputType
    };
  },

  computed: {},
  methods: {
    change(value) {
      // console.log(value);
      this.$emit("input", value);
    },
    changeInputTypeEvent() {
      this.new_inputType == "text"
        ? (this.new_inputType = "password")
        : (this.new_inputType = "text");
    }
  },
  mounted() {},
  beforeDestroy() {},
  activated() {}
};
</script>
<style scoped lang="less">
.eyes_icon {
  cursor: pointer;
  img {
    width: 24px;
    height: 24px;
  }
}
</style>
