<!--  -->
<template>
  <div :class="system.isPhone ? 'wap_login_all' : ''" class="login_all">
    <!-- 左边图片 -->
    <div v-if="!system.isPhone" class="login_left">
      <img :src="$t('loginData.pageLeft_BGImg')" />
    </div>
    <!-- 右边内容 -->
    <div :class="system.isPhone ? 'wap_login_right' : 'login_right'">
      <img
        v-if="!system.isPhone"
        class="logo"
        :src="require('@/assets/img/login/LOGO 2@2x.png')"
      />
      <div v-if="system.isPhone" class="wap_login_top">
        <img class="logo" :src="require('@/assets/img/login/LOGO 2@2x.png')" />
        <div class="top_menu">
          <div
            @click="qiehuanEvent('login')"
            x
            :class="actionStep === 'login' ? 'div_xuanzhong' : ''"
          >
            {{ $t("loginData.login") }}
          </div>
          <div
            @click="qiehuanEvent('registered')"
            :class="actionStep === 'registered' ? 'div_xuanzhong' : ''"
          >
            {{ $t("loginData.registered") }}
          </div>
        </div>
      </div>
      <login
        @qiehuanEvent="qiehuanEvent"
        @stepChangeEven="stepChangeEven"
        :actionStep="actionStep"
      ></login>
      <div v-if="!system.isPhone" class="qiehuan_men font18">
        <div v-if="actionStep !== 'login'" class="goto_login">
          {{ $t("loginData.toLogin.text1") }}
          <a @click="qiehuanEvent('login')">{{
            $t("loginData.toLogin.text2")
          }}</a>
        </div>
        <div v-else class="other">
          <a @click="qiehuanEvent('registered')">{{ $t("nameClickzhuce") }}</a>
          <a @click="qiehuanEvent('forgetPassword')">{{
            $t("loginData.forgetPassword")
          }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//公共组件导入处;

//子组件导入处;
import login from "./components/login";

//方法导入处;

export default {
  components: {
    login
  },
  data() {
    return {
      actionStep: "login"
    };
  },
  props: {
    //popupVisible: {
    //type: Boolean,
    //default: false
    //}
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    qiehuanEvent(value) {
      console.log(value, 555);
      this.actionStep = value;
    },
    stepChangeEven(value) {
      this.actionStep = value;
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {} //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped lang="less">
.login_all {
  display: flex;
  height: 100vh;
  width: 100%;
  div {
    flex: 1;
  }
  .login_left {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .login_right {
    background-color: white;
    max-width: calc(100vw * 62 / 192);
    min-width: 500px;

    .qiehuan_men {
      width: 436px;
      margin: auto;
      text-align: left;

      .goto_login {
        margin-top: 100px;
      }
      .other {
        margin-top: 80px;
        display: flex;
        justify-content: space-between;
      }
      a {
        color: #1977fb;
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .logo {
      width: 71px;
      height: 70px;
      float: left;
      margin-left: calc(100vw * 4.3 / 192);
      margin-top: calc(100vw * 2.8 / 192);
    }
  }

  .wap_login_right {
    width: 100%;
    background-color: #f2f2f2;
    .wap_login_top {
      background-color: white;
      border-radius: 0 0 18px 18px;
      height: calc(100vw * 22.3 / 37.5);
      position: relative;
      .logo {
        height: calc(100vw * 9.7 / 37.5);
        width: calc(100vw * 9.6 / 37.5);
        margin-top: calc(100vw * 4 / 37.5);
      }
      .top_menu {
        display: flex;
        position: absolute;
        width: 100%;
        bottom: 0px;
        padding-left: 10%;
        padding-right: 10%;
        box-sizing: border-box;
        height: calc(100vw * 3 / 37.5);
        > div {
          border-bottom: 2px solid rgba(25, 119, 251, 0);
          width: calc(100vw * 12 / 37.5);
          margin-right: 30px;
          font-size: calc(100vw * 1.6 / 37.5);
        }
        .div_xuanzhong {
          border-bottom: 2px solid rgba(25, 119, 251, 1);
        }
        > div:last-child {
          margin-right: 0;
        }
      }
    }

    /deep/.login_page_all {
      margin-top: 0;
      .login_ruleForm {
        background-color: #f2f2f2;
        padding-bottom: calc(100vw * 4 / 37.5);
        width: auto;
        padding-left: calc(100vw * 4.5 / 37.5);
        padding-right: calc(100vw * 4.5 / 37.5);
        .xieyihang {
          margin-top: 50px;
        }
        .eyes_icon {
          display: flex;
          height: 100%;
          align-content: center;
        }
        .el-input__suffix {
          display: flex;
          align-items: center;
        }
        .login_but {
          width: 100%;
          // height: calc(100vw * 6.4 / 37.5);
          border-radius: 20px;
        }
        .input_hang {
          margin-top: 20px;
        }
        .input_hang:first-child {
          margin-top: calc(100vw * 4.8 / 37.5);
        }
        .el-input__inner {
          background-color: #f2f2f2;
        }
        .el-form-item {
          margin-bottom: 0;
        }
        .xieyi_sleceted_icon {
          width: 16px;
          height: 16px;
        }
        .el-textarea__inner {
          height: calc(100vw * 12 / 37.5);
          border: none;
          border-bottom: 1px solid #dcdfe6;
          border-radius: 0;
        }
        .el-form-item__label {
          font-size: calc(100vw * 1.2 / 37.5);
          padding: 0;
          line-height: calc(100vw * 1.4 / 37.5);
          // margin-top: calc(100vw * 2.4 / 37.5);
          display: flex;
          align-items: flex-end;
          color: rgba(0, 0, 0, 0.4);
        }
        .el-input__inner {
          font-size: calc(100vw * 1.2 / 37.5);
          height: auto;
          line-height: calc(100vw * 3.2 / 37.5);
          border-radius: 0px;
          border: 1px none #dcdfe6;
          border-bottom: 1px solid #dcdfe6;
        }
      }
    }
  }
}
.wap_login_all {
  background-color: #f2f2f2;
  // height: auto;
}
</style>
