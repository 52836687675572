<template>
  <div class="xieyi_all">
    <img
      @click="xieyiClickEvent"
      class="xieyi_sleceted_icon"
      :src="
        xieyiStatys
          ? require('@/assets/img/login/xuanzhong.png')
          : require('@/assets/img/login/Ellipse 22@2x.png')
      "
    />
    <div :class="system.isPhone ? 'wapfont14' : 'font18'">
      {{ $t("loginData.xieyi.text1") }}
      <a
        :href="
          'https://www.hooloogo.com/register/agreement?t=' +
            new Date().getTime()
        "
        target="_blank"
        >{{ $t("loginData.xieyi.text2") }}</a
      >、<a
        :href="
          'https://www.hooloogo.com/deliveryclause.html?t=' +
            new Date().getTime()
        "
        target="_blank"
        >{{ $t("loginData.xieyi.text3") }}</a
      >
    </div>
  </div>
</template>

<script>
//公共组件导入处;

//子组件导入处;

//方法导入处;

export default {
  components: {},
  data() {
    return {};
  },
  props: ["xieyiStatys"],
  computed: {},
  methods: {
    xieyiClickEvent() {
      this.$emit("xieyiClickEvent");
    },
  },
  mounted() {},
  beforeDestroy() {},
  activated() {},
};
</script>
<style scoped lang="less">
.xieyi_all {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .xieyi_sleceted_icon {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    cursor: pointer;
  }
  a {
    color: #1977fb;
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>
